<script setup>
import { computed, onMounted, ref, inject } from 'vue'
import { useMq } from 'vue3-mq'
import { useSyncStore, useMainStore, useAuthStore } from '@/stores'
import db from '@/libs/db'
import { syncAllExcav, syncAllData, getAllChanges } from '@/utils'
import { BASE_URL } from '@/configs/urls'

import SyncContent from './components/sync-content.vue'

const syncStore = useSyncStore()
const mainStore = useMainStore()
const authStore = useAuthStore()
const mq = useMq()

const $notify = inject('$notify')

const showWarning = ref(false)

const noSync = computed(() => {
  return !!syncStore.changesList?.length || !!syncStore.dataChangesList?.length || false
})

const changesLoaded = computed(() => {
  return syncStore.changesLoaded || false
})

const clickHandler = (action) => {
  switch (action) {
    case 'sync':
      syncData()
      break
    case 'continue':
      mainStore.setNoSyncMode(true)
      showWarning.value = false
      break
  }
}

const generate1MBFile = () => {
  const sizeInBytes = 512 * 1024
  const text = 'a'.repeat(sizeInBytes)
  const blob = new Blob([text], { type: 'text/plain' })

  return new File([blob], 'test-file.txt', { type: 'text/plain' })
}

const measureInternetSpeed = async () => {
  const file = generate1MBFile()
  const formData = new FormData()
  const startTime = new Date().getTime()
  const MIN_SPEED = 30

  formData.append('file', file)

  try {
    const response = await fetch(`${BASE_URL}/api/test/upload/`, {
      body: formData,
      method: 'POST',
      headers: {
        Authorization: `Token ${authStore?.userToken}`
      }
    }).then((response) => response.json())

    const endTime = new Date().getTime()
    const durationInSeconds = (endTime - startTime) / 1000
    const fileSizeInKB = file.size / 1024
    const speedInKBps = (fileSizeInKB / durationInSeconds).toFixed(2)
    refSpeedInKps.value = speedInKBps

    if (response) {
      if (speedInKBps < MIN_SPEED) {
        throw new Error(`Недостаточная скорость интернета: ${speedInKBps} KB/s`)
      }
    }
  } catch (e) {
    throw new Error(`Недостаточная скорость интернета`)
  }
}

let refSpeedInKps = ref(0)

const syncData = async () => {
  try {
    syncStore.setField('loading', true)

    await measureInternetSpeed()

    showWarning.value = false
    await syncAllExcav(syncStore.changesList)
    await syncAllData(syncStore.dataChangesList)
  } catch (e) {
    let message = `Произошла ошибка при синхронизации данных. ${e}`
    if (e?.response?.status === 409) {
      const { response } = e
      const { data } = response
      await removeItemFromTable('created', data)
      await getAllChanges()
    } else if (e?.response?.status === 400) {
      const errorText = Object.entries(e.response?.data || {})
        .map(([key, value]) => `${key} – ${value.join(', ')}`)
        .join('. ')

      message += `. ${errorText}`
    }

    $notify({
      message,
      type: 'error'
    })
  } finally {
    syncStore.setField('loading', false)
  }
}

const removeItemFromTable = async (tableName, data) => {
  try {
    if (data?.images?.length || data.table === 'images') {
      const image = await db.images.get({ item_id: data.item_id })
      await db.created.where('item_id').equals(image.id).delete()
      await db.images.where('item_id').equals(data.item_id).delete()
    }

    await db[tableName]
      .where({
        table: data.table,
        item_id: data.item_id
      })
      .delete()
    await db.table(data.table).update(data.item_id, { server_id: data.id })
    getAllChanges()
  } catch (error) {
    console.error('Error: ' + error)
  }
}

onMounted(async () => {
  if (mainStore.isOnline && noSync.value && changesLoaded.value) {
    showWarning.value = true
  }

  await getAllChanges()
})

const modalTitleWarning = 'Восстановление онлайн-режима'
const alertText =
  'Настоятельно рекомендуем синхронизировать данные с сервером сразу же при появлении стабильного интернет-соединения, чтобы избежать потери данных'
</script>

<template>
  <div class="sync">
    <s-title type="title">
      Панель синхронизации {{ refSpeedInKps ? `(скорость: ${refSpeedInKps}kbs)` : '' }}
    </s-title>

    <sync-content @sync="clickHandler('sync')" />

    <s-modal
      :marginY="mq.current === 'lg' ? 48 : 0"
      :title="modalTitleWarning"
      no-close-button
      :show="showWarning"
      :fullscreen="mq.current !== 'lg'"
      :close-by-overlay="false"
    >
      <div class="sync__content">
        <s-title type="small">Внимание! На устройстве имеются несинхронизированные данные</s-title>
        <s-text>
          Вы можете синхронизировать эти данные с сервером или продолжить работу в оффлайн-режиме,
          накапливая данные на устройстве
        </s-text>
        <s-alert :title="alertText" icon="circle-exclamation" type="warning" />
      </div>
      <template #footer>
        <s-div :gap="1" stretch style="flex-direction: column">
          <s-button stretch @click="clickHandler('continue')">
            Продолжить в оффлайн-режиме
          </s-button>
          <s-button
            v-loading="syncStore.loading"
            stretch
            type="success"
            @click="clickHandler('sync')"
          >
            Синхронизировать данные
          </s-button>
        </s-div>
      </template>
    </s-modal>
  </div>
</template>

<style lang="scss">
.sync {
  display: grid;
  grid-gap: 1rem;
  align-content: start;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
